

/* checkbox */
.label-hyphen,
.label-synonymns,
.label-otherword,
.label-com,
.label-couk,
.label-reverse {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-variant: small-caps;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  width: 25px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.label-hyphen {
  align-self: center;
  bottom: 12px
 }

.label-otherword {
  font-size: 14px;
  width: auto;
}

.label-synonymns {
  font-style: italic;
  bottom: -7px;
    left: 5px;
    position: relative;
}

input {
  color: rgb(82, 169, 209);
}


/* Hide the browser's default checkbox */
.label-hyphen input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */

.checkmark-hyphen,
.checkmark-synonymns,
.checkmark-com,
.checkmark-reverse {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  color: #fff;
  transition: all 0.35s ease-in-out;
}



.checkmark-otherword {
  background-color: #eee;
  color: #000;
  padding: 2px 4px 3px;
  line-height: 2;
}

.checkmark-synonymns {
  height: 23px;
}


.checkmark-com {
  width: 64px;
}

.checkmark-reverse {
    width: 76px;
}


/* On mouse-over, add a grey background color */
.label-hyphen:hover input ~ .checkmark-hyphen,
.label-synonymns:hover input ~ .checkmark-synonymns,
.label-com:hover input ~ .checkmark-com,
.label-couk:hover input ~ .checkmark-com,
.label-reverse:hover input ~ .checkmark-reverse {
  background-color: #ccc;
  position: absolute;
  left: 0;
  /* color: #000; */

  /* border: lightgoldenrodyellow 3px solid; */
}

.label-otherword:hover input ~ .checkmark-otherword {
  background-color: #ccc;
}

.label-otherword > input {
  visibility: hidden;
  width: 0;
}

.label-hyphen:hover input ~ .checkmark:after,
.label-hyphen:hover input ~ .checkmark-com:after {
  content: "";
  position: absolute;
  left: 7px;
  top: 11px;
  width: 9px;
  height: 0px;
  border: solid rgb(82, 189, 224);
  border-width: 0 3px 3px 0;
  display: block;

  /* border: lightgoldenrodyellow 3px solid; */
}

.label-com:hover input ~ .checkmark:after,
.label-couk:hover input ~ .checkmark:after {
  content: "com";
  position: absolute;
  left: 7px;
  top: 0px;
  border-width: 0 3px 3px 0;
  display: block;

  /* border: lightgoldenrodyellow 3px solid; */
}

.label-reverse:hover input ~ .checkmark-reverse:after {
    content: "reverse";
    position: absolute;
    left: 7px;
    top: 2px;
    border-width: 0 3px 3px 0;
    display: block;
  
    /* border: lightgoldenrodyellow 3px solid; */
  }


/* When the checkbox is checked, add a pink background */
.label-hyphen input:checked ~ .checkmark-hyphen,
.label-synonymns input:checked ~ .checkmark-synonymns,
.label-otherword input:checked ~ .checkmark-otherword,
.label-com input:checked ~ .checkmark-com,
.label-couk input:checked ~ .checkmark-com,
.label-reverse input:checked ~ .checkmark-reverse {
  background-color: rgb(252, 146, 146);
}

/* Show the checkmark when checked */
/* .label-hyphen input:checked ~ .checkmark-hyphen:after,
.label-synonymns input:checked ~ .checkmark-synonymns:after,
.label-com input:checked ~ .checkmark-com:after,
.label-couk input:checked ~ .checkmark-com:after,
.label-reverse input:checked ~ .checkmark-reverse:after {
  display: block;
} */

/* Style the checkmark/indicator */
.label-hyphen .checkmark-hyphen:after {
  content: "";
  position: absolute;
  left: 7px;
  top: 11px;
  width: 9px;
  height: 0px;
  border: solid white;
  border-width: 0 3px 3px 0;
  display: block;
}

.label-com .checkmark-com:after,
.label-couk .checkmark-com:after,
.label-reverse .checkmark-reverse:after {
  position: absolute;
  left: 7px;
  top: 2px;
  font-size: 16px;
  border-width: 0 3px 3px 0;
  display: block;
  color: #fff;
}
.label-synonymns .checkmark-synonymns:after {
  
  content: "S";
  position: absolute;
  bottom: 1px;
  left: 7px;
  font-size: 18px;

  
}

/* .label-synonymns{
  position: relative;
} */

.label-synonymns .checkmark-synonymns.loaded-true:after {
  content: url(./assets/arrow.png);
  position: absolute;
  left: -238px;
  top: -239px;
  width: 500px;
  height: 500px;
  transform: scale(0.06);
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin { 
  from { 
      transform: rotate(0deg) scale(0.04);
  } to { 
      transform: rotate(360deg) scale(0.04);
  }
}

.label-com .checkmark-com:after {
  content: ".com";
}
.label-couk .checkmark-com:after {
  content: ".co.uk";
}

.label-reverse .checkmark-reverse:after {
    content: "reverse";
}

.wrapper-options {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 160px;
}

header {
    text-align: center;
    mix-blend-mode: multiply;
  }
  h1 {
    position: relative;
    font-size: 4rem;
    left: 32px;
    color: blue;
    white-space: nowrap;
    transform: skew(-10deg);
    display: inline-block;
  }
  h1 .glitch1,
  h1 .glitch2 {
    position: absolute;
    top: 0;
    left: 0;
  }
  h1::before,
  h1::after,
  h1 .glitch2::before,
  h1 .glitch2::after,
  h1 .glitch1::before,
  h1.notsafari .glitch1::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    color: rgb(253, 127, 137);
    z-index: 1;
    pointer-events: none;
    mix-blend-mode: multiply;
  }
  h1 .glitch2::before {
    color: cyan;
    animation: trans4 3.2s infinite;
    z-index: -1;
    clip-path: inset(62% 0 0 0);
  }
  h1 .glitch2::after {
    color: yellow;
    animation: trans5 3.2s infinite;
    z-index: -1;
    clip-path: inset(62% 0 0 0);
  }

  h1 .glitch2::after {
    color: black;
    animation: trans5 3.2s infinite;
    z-index: -1;
    clip-path: inset(62% 0 0 0);
  }

  h1.notsafari::before {
    clip-path: inset(0 0 43% 0);
    animation: trans6 3.2s infinite;
    color: black;
  }
  h1::after {
    clip-path: inset(62% 0 0 0);
  }
  h1.notsafari .glitch1::before {
    clip-path: inset(57% 0 42% 0);
    animation: trans2 .8s steps(1, start) infinite;
    animation-fill-mode: both;
    animation-delay: 0.2s;
    color: black;
  }
  h1.notsafari .glitch1::after {
    clip-path: inset(58% 0 38% 0);
    animation: trans1 .8s steps(1, start) infinite;
    animation-fill-mode: both;
    color: black;
  }

  h1.safari .glitch1::after {
    clip-path: inset(38% 0 38% 0);
    animation: trans1 .8s steps(1, start) infinite;
    animation-fill-mode: both;
    color: black;
  }
  .popup-title{
    text-align: center;
    font-size: 18px;
    text-decoration: overline;
  }
  .popup {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  @keyframes trans1 {
    0% {
      transform: translateX(-1%);
    }
    20% {
      transform: translateX(0.5%);
    }
    40% {
      transform: translateX(-0.2%);
    }
    60% {
      transform: translateX(0.6%);
    }
    90% {
      transform: translateX(0.8%);
    }
    100% {
      transform: translateX(-1%);
    }
  }
  @keyframes trans2 {
    0% {
      transform: translateX(5%);
    }
    20% {
      transform: translateX(2.5%);
    }
    55% {
      transform: translateX(1.8%);
    }
    60% {
      transform: translateX(2.6%);
    }
    80% {
      transform: translateX(2.8%);
    }
    100% {
      transform: translateX(3%);
    }
  }
  @keyframes trans3 {
    0% {
      transform: translateX(1%);
    }
    20% {
      transform: translateX(1.1%);
    }
    45% {
      transform: translateX(1.2%);
    }
    70% {
      transform: translateX(1.4%);
    }
    90% {
      transform: translateX(0.6%);
    }
    100% {
      transform: translateX(1.2%);
    }
  }
  @keyframes trans4 {
    0% {
      transform: translateX(0%);
    }
    60% {
      transform: translateX(0.5%);
    }
    60.001% {
      transform: translateX(0.3%);
    }
    100% {
      transform: translateX(0.2%);
    }
  }
  @keyframes trans5 {
    0% {
      transform: translateX(0%);
    }
    60% {
      transform: translateX(-0.5%);
    }
    60.001% {
      transform: translateX(-0.3%);
    }
    100% {
      transform: translateX(-0.2%);
    }
  }
  @keyframes trans6 {
    0% {
      transform: translateX(0.6%);
    }
    60% {
      transform: translateX(1.1%);
    }
    60.001% {
      transform: translateX(0.9%);
    }
    100% {
      transform: translateX(1.2%);
    }
  }


.wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}


.col0 {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  top: 14px;
  left: 20px;
  width: 160px;
}
.col0 p{
  font-size: 12px;
}
.col0 .deletebtn {
  text-align: center;
}

.col1,
.col2 {
  text-align: center;
  width: 260px;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 140px;
  margin: 0 auto;
}

input {
    display: inline;
    border: none;
    border-bottom: 4px solid aliceblue;
    padding: 3px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    text-transform: uppercase;
    left: 2px;
    position: relative;
}

.add {
  background-color: #eee;
  display: block;
  width: 50px;
  margin: 6px auto;
  cursor: pointer;
  color: rgb(82, 189, 224);
  transition: all 0.35s ease-in-out;
}

.add:hover {
  background-color: #ccc;
}

#availableDomains {
  display: flex;
  flex-direction: row;
  margin: 20px 50px;
  padding: 10px;
  border: 5px rgb(240, 248, 255) solid;
  border-bottom: none;
  flex-wrap: wrap;
  justify-content: left;
  line-height: 1.7;
}

.res {
  margin-bottom: 5px;
  min-width: 18%;
  padding: 0 1%;
  text-transform: lowercase;
}

.deletebtn {
    width: 20px;
    margin-top: 5px;
    height: 23px;
    display: inline-block;
    background-color: aliceblue;
    cursor: pointer;
    color: rgb(82, 189, 224);
}

.nottaken {
  color: green;
  order: -1;
}

.cantexist {
  color: lightgrey;
  margin-bottom: -10px;
}

.taken {
  color: lightcoral;
  margin-bottom: -10px;
}

.noquota {
  color: black;
  order: 1;
  margin-bottom: -10px;
}

p {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-variant: small-caps;
  margin-bottom: 4px;
}

#main-search-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  transform: translateX(-15%);
  font-size: 17px;
  padding-left: 12px;
  text-decoration: none;
}

#main-search-btn:active {
  position: relative;
  top: 1px;
}
#main-search-btn:focus {
  outline: none;
}

.synonymnbtn {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    width: 20px;
    margin-left: 10px;
    margin-top: 5px;
    height: 23px;
    display: inline-block;
    background-color: aliceblue;
    font-variant: small-caps;
    cursor: pointer;
    color: rgb(82, 189, 224);
}


/* Place Holder CSS */
::-webkit-input-placeholder {
  color: #000;
  opacity: 0.2;
  -webkit-transition: opacity 0.35s ease-in-out;
  transition: opacity 0.35s ease-in-out;
}
:-moz-placeholder {
  color: #000;
  opacity: 0.2;
  -moz-transition: opacity 0.35s ease-in-out;
  transition: opacity 0.35s ease-in-out;
}
::-moz-placeholder {
  color: #000;
  opacity: 0.2;
  -moz-transition: opacity 0.35s ease-in-out;
  transition: opacity 0.35s ease-in-out;
}
:-ms-input-placeholder {  
  color: #000;
  opacity: 0.2;
  -ms-transition: opacity 0.35s ease-in-out;
  transition: opacity 0.35s ease-in-out;
}

/* Place Holder CSS for Focus */
:hover::-webkit-input-placeholder {
  opacity: 0.4;
  -webkit-transition: opacity 0.35s ease-in-out;
  transition: opacity 0.35s ease-in-out;
}
:hover:-moz-placeholder {
  opacity: 0.4;
  -moz-transition: opacity 0.35s ease-in-out;
  transition: opacity 0.35s ease-in-out;
}
:hover::-moz-placeholder {
  opacity: 0.4;
  -moz-transition: opacity 0.35s ease-in-out;
  transition: opacity 0.35s ease-in-out;
}
:hover:-ms-input-placeholder {  
  opacity: 0.4;
  -ms-transition: opacity 0.35s ease-in-out;
  transition: opacity 0.35s ease-in-out;
}

/* Place Holder CSS for Focus */
:focus::-webkit-input-placeholder {
  opacity: 0;
  /* transform: translate(70%); */
  -webkit-transition: all 0.35s ease-in-out;
  transition: all 0.35s ease-in-out;
}
:focus:-moz-placeholder {
  opacity: 0;
  -moz-transition: opacity 0.35s ease-in-out;
  transition: opacity 0.35s ease-in-out;
}
:focus::-moz-placeholder {
  opacity: 0;
  -moz-transition: opacity 0.35s ease-in-out;
  transition: opacity 0.35s ease-in-out;
}
:focus:-ms-input-placeholder {  
  opacity: 0;
  -ms-transition: opacity 0.35s ease-in-out;
  transition: opacity 0.35s ease-in-out;
}
*:focus {
  outline: none;
}

/* popup code */
/* popup code */
/* popup code */

.error-popup {
  position: absolute;
  text-align: center;
  left: 50%;
  top: 17.5%;
  padding: 10px 10px 11px;
  color: white;
  font-weight: 700;
  background-color: rgba(252, 146, 146, 0.8);
  border-radius: 10px;
  transform: translate(-50%, -50%);
}

.error-popup p{
  margin: 0;
}


/* loading icon code */
/* loading icon code */
/* loading icon code */

.loading-icon {
  max-width: 300px;
  position: relative;
  display: block;
  transform-origin: center;
  padding-top: 19px;
  padding-bottom: 18px;
  background-color: white;
}

/* error popup code */
/* error popup code */
/* error popup code */
.error-popup {
  animation: FadeAnimation 1s ease-in forwards;
}

@keyframes FadeAnimation {
  0% {
    opacity: 1;
    visibility: visible;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

/* popup controls */

.popup-controls {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-controls button {
  padding: 20px;
  min-width: 80px;
  margin: 5px;
  border: none;
  background: darkgrey;
  position: relative;
  background-color: #eee;
  color: #000;
  padding: 20px;
  margin-bottom: 10px;
  line-height: 2;
}

.total-combinations{
  text-align: left;
  font-size: 12px;
  color: rgb(252, 146, 146);
  position: absolute;
  bottom: -9px;
  width: 300px;
  font-variant: small-caps;
  left: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.display-combinations {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
  Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-variant: small-caps;
  position: relative;
  align-self: start;
  cursor: pointer;
  font-size: 22px;
  text-align: center;
  font-size: 12px;
  margin: 5px 0;
  padding: 5px 7px;
  border: none;
  background: rgb(248, 248, 248);
  position: relative;
  color: rgb(61, 61, 61);
  transition: all 1s;
}

.display-combinations.danger-0 {
  visibility: hidden;
}

.display-combinations.danger-none {
  background:rgb(125, 204, 142);
}

.display-combinations.danger-orange {
  background:rgb(235, 184, 89);
}

.display-combinations.danger-red {
  background:rgb(233, 93, 93);
}

/* mobile styles */

@media screen and (max-width: 767px){
  .display-combinations {
    align-self: center;
  }
  .total-combinations{
    text-align: center;
    left: -14px;
    bottom: 9px;
    font-size: 14px;
  }
  .col0 {
    top: initial;
    left: initial;
    width: initial;
  }
  .col0 p{
    font-size: 12px;
  }

  
  .error-popup {
    top: 50%;
    position: fixed;
    color: black;
    width: calc(100% - 150px);
    background-color: white;
    height: 100vh;
    text-align: center;
    width: 100%;
    z-index: 999;
  }

  .error-popup p {
    top: 50%;
    position: relative;
  }

  h1{
    font-size: 2.8rem;
    left: 0;
  }
  .wrapper {
    flex-direction: column;
    align-items: center;
  }
  .wrapper-options label {
    left: -20px;
  }

  .wrapper-options {
    padding: 10px 10px 30px;

    align-items: center;
  }
  
  #main-search-btn {
    padding-left: 52px;
  }

  #availableDomains {
    display: flex;
    flex-direction: column;
    margin: 20px 50px;
    padding: 10px;
    text-align: center;
    border: 5px aliceblue solid;
    border-bottom: none;
    flex-wrap: nowrap;
    height: initial;
    justify-content: left;
    line-height: 1.7;
  }

  .res {
    max-width: initial;
  }

  #reverse {
    left: -7px;
  }

  .col2 p {
    margin-top: 50px;
  }

  .checkmark-hyphen{
    top: 10px;
  }

  .label-hyphen{
    bottom: initial;
  }
}